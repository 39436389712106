const overviewState = {
    currentSorted: null,
    currentSortDirection: null,
    searchTerm: null,
};

const OverviewStore = {
    namespaced: true,
    state: {
        users: overviewState,
        companies: { ...overviewState, showAP: null, showEV: null },
        divisions: overviewState,
        companyUsers: overviewState,
    },

    mutations: {
        setState(state, { page, newState }) {
            state[page] = newState;
        },
        clearState(state, page) {
            state[page] = overviewState;
        },
    },
    getters: {
        getState: (state) => (page) => state[page],
    },
};

export default OverviewStore;
