<template>
    <MetadataOverview title="General" :metadata="metadata" />
</template>

<script setup>
import MetadataOverview from "@/components/layout/MetadataOverview.vue";
import { computed } from "vue";

const props = defineProps({
    division: {
        type: Object,
        required: true,
    },
});

const metadata = computed(() => {
    return [
        {
            key: "Language",
            value: props.division.language ? props.division.language.toUpperCase() : "−",
            empty: !props.division.language,
        },
        {
            key: "Company",
            value: props.division.company.name,
            isLink: true,
            link: `/company/${props.division.company?.id}`,
        },
        {
            key: "Teamleader ID",
            value: props.division.teamleaderId ? props.division.teamleaderId : "−",
            empty: !props.division.teamloaderId,
        },
        {
            key: "Teamleader UUID",
            value: props.division.teamleaderUuid ? props.division.teamleaderUuid : "−",
            emtpy: !props.division.teamleaderUuid,
        },
    ];
});
</script>

<style lang="scss" scoped />
