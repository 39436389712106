<template>
    <MetadataOverview title="User" :metadata="metadata" />
</template>

<script setup>
import MetadataOverview from "@/components/layout/MetadataOverview.vue";
import { computed } from "vue";
import { format } from "date-fns";

const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
});

function formatDate(date) {
    return format(new Date(date), "dd MMM yyyy HH:mm:ss b");
}

const metadata = computed(() => {
    const data = [
        {
            key: "Created at",
            value: formatDate(props.user.createdTimestamp),
            isEmpty: "",
        },
        {
            key: "User enabled",
            value: props.user.isEnabled ? "yes" : "no",
            isEmpty: "",
        },
        {
            key: "Email verified",
            value: props.user.isEmailVerified ? "yes" : "no",
            isEmpty: "",
        },
    ];
    if (props.user.lastLogin) {
        data.push({
            key: "Last login",
            value: formatDate(props.user.lastLogin),
            isEmpty: "",
        });
    }
    return data;
});
</script>
