var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.SettingsLayout,{attrs:{"loading":_setup.loading,"headerProps":{
            title: 'Edit user',
            backButtonTo: { path: _setup.backButtonUrl },
            backButtonLabel: 'Users',
            onTabSelect: _setup.onTabSelect,
            tabs: _setup.tabs,
            activeTab: _setup.activeTab,
        }},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('h2',{staticClass:"header__subtitle body1"},[(_setup.subheaderTitle)?_c('span',[_c(_setup.FontAwesomeIcon,{staticClass:"header__subtitle__icon",attrs:{"icon":['far', _setup.subheaderIcon],"size":"sm"}}),_c('span',[_vm._v(_vm._s(_setup.subheaderTitle))])],1):_c('span',[_vm._v("Manage user settings.")])])]},proxy:true},{key:"main",fn:function(){return [(_setup.activeTab === 'edit')?_c(_setup.EditUserForm,{ref:"edit",attrs:{"data":_setup.user,"previousPage":_setup.backButtonUrl}}):_vm._e(),(_setup.activeTab === 'parent')?_c(_setup.EditUsersParent,{ref:"parent",attrs:{"selectedParent":_setup.user.company ? _setup.user.company : {},"selectedDivisions":_setup.user.divisions,"isParentCompany":_setup.user.companyLevelAccess,"username":_setup.user.username}}):_vm._e(),(_setup.activeTab === 'support')?_c(_setup.UserSupport,{ref:"support",attrs:{"data":_setup.user}}):_vm._e()]},proxy:true},{key:"aside",fn:function(){return [(_setup.isSupportTabActive)?_c(_setup.SupportUserOverview,{attrs:{"user":_setup.user}}):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c(_setup.SettingsFooter,[_c('portal-target',{attrs:{"name":"editUserFooter"}})],1)]},proxy:true}])}),(_setup.intendedTabSwitch !== null)?_c(_setup.ConfirmPopup,{attrs:{"title":"Warning","message":"There are unsaved changes in this tab. Please save or cancel changes before proceeding.","confirm-btn":"Save","confirmButtonStyle":"primary","confirmBtnIsLoading":_setup.confirmBtnIsLoading},on:{"confirm":_setup.handleConfirmation,"cancel":function($event){_setup.intendedTabSwitch = null}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }