<template>
    <div>
        <SettingsForm v-if="!loading" class="settings_form" ref="formEl">
            <SettingsBlock class="settings_block" title="User information">
                <SettingsFieldSet class="settings_fieldset" label="Basic user information settings">
                    <label class="body2">
                        Here you fill in the basic user information. This information will be shown
                        on the user’s profile.
                    </label>
                    <label class="subtitle3">Name</label>
                    <TextInput
                        ref="firstNameField"
                        v-model="user.firstName"
                        label="First name"
                        enableExternalValidation
                        :externalValidationError="v$.user.firstName.$error"
                        :externalValidationErrorMessage="resolveErrorMessage(v$.user.firstName)"
                    />
                    <TextInput
                        v-model="user.lastName"
                        label="Last name"
                        enableExternalValidation
                        :externalValidationError="v$.user.lastName.$error"
                        :externalValidationErrorMessage="resolveErrorMessage(v$.user.lastName)"
                    />
                    <label class="subtitle3">Email</label>
                    <TextInput
                        v-model="user.email"
                        label="Email"
                        enableExternalValidation
                        :externalValidationError="v$.user.email.$error"
                        :externalValidationErrorMessage="resolveErrorMessage(v$.user.email)"
                    />
                    <label class="subtitle3">Generated username</label>
                    <label class="body2">
                        The username will be generated, so the numbers at the end are not final yet.
                        The final username is visible in the overview.
                    </label>
                    <TextInput :value="user.username" label="Username" disabled />
                </SettingsFieldSet>
            </SettingsBlock>
            <SettingsBlock
                v-if="user.isEvaluation && !user.isEvaluationSourceUser"
                class="settings_block"
                title="Connection"
            >
                <SettingsFieldSet class="settings_fieldset" label="Connect user">
                    <label class="body2 language_body">
                        If needed, choose a Research user to give it’s corresponding rights to this
                        user.
                    </label>
                    <Dropdown
                        class="text_input_holder"
                        v-model="user.connection"
                        placeholder="Choose a connection"
                        searchAble
                        :options="connectionUsers"
                        :disabled="!hasUserPlatforms"
                    />
                    <span v-if="!hasUserPlatforms" class="unknown_platform">
                        The user can not linked because the platform is unknown. Please connect a
                        platform to the company (or division).
                    </span>
                </SettingsFieldSet>
            </SettingsBlock>
            <SettingsBlock class="settings_block" title="Language">
                <SettingsFieldSet class="settings_fieldset" label="Account language">
                    <label class="body2 language_body">
                        The account language defines the preferred communication about the account
                        such as login and passwords.
                    </label>
                    <Dropdown
                        class="text_input_holder"
                        v-model="user.keycloakLanguage"
                        placeholder="Choose a language"
                        :options="languages"
                        enableExternalValidation
                        :externalValidationError="v$.user.keycloakLanguage.$error"
                        :externalValidationErrorMessage="
                            resolveErrorMessage(v$.user.keycloakLanguage)
                        "
                    />
                </SettingsFieldSet>
            </SettingsBlock>
            <SettingsBlock
                v-if="!$route.query.divisionId"
                class="settings_block"
                title="Select divisions"
            >
                <Checkbox
                    class="checkbox"
                    label="I want to select the divisions"
                    v-model="enableSelectDivision"
                    @click="toggleCheckbox"
                />
                <SettingsFieldSet class="settings_fieldset" label="Select divisions">
                    <label class="body2 language_body">
                        Instead of having access to all divisions of this company, you can assign
                        specific divisions to this user
                    </label>
                    <!--generated list with selected divisions-->
                    <div
                        v-for="(item, index) in usersDivisions"
                        :key="index"
                        class="division_picker"
                    >
                        <Dropdown
                            class="text_input_holder"
                            placeholder="Select a division"
                            v-model="item.id"
                            :disabled="!enableSelectDivision"
                            searchAble
                            :options="divisionsList"
                            enableExternalValidation
                            :externalValidationError="v$.$dirty && !item.id"
                            externalValidationErrorMessage="This field is required"
                        />
                        <InlineButton
                            v-if="enableSelectDivision"
                            grey
                            @click.native="prepareDelete(index)"
                        >
                            <FontAwesomeIcon
                                class="delete_icon"
                                slot="icon"
                                :icon="['far', 'times']"
                                size="lg"
                            />
                        </InlineButton>
                    </div>
                    <div v-if="!usersDivisions.length || addingNewDivision" class="division_picker">
                        <Dropdown
                            class="text_input_holder"
                            placeholder="Select a division"
                            :disabled="!enableSelectDivision"
                            v-model="newDivisionId"
                            searchAble
                            :options="unselectedDivisionsList"
                            enableExternalValidation
                            :externalValidationError="v$.newDivisionId.$error"
                            :externalValidationErrorMessage="resolveErrorMessage(v$.newDivisionId)"
                        />
                        <InlineButton
                            grey
                            v-if="enableSelectDivision && addingNewDivision"
                            @click.native="addingNewDivision = false"
                        >
                            <FontAwesomeIcon
                                class="delete_icon"
                                slot="icon"
                                :icon="['far', 'times']"
                                size="lg"
                            />
                        </InlineButton>
                    </div>
                    <button
                        v-if="
                            enableSelectDivision &&
                            !addingNewDivision &&
                            unselectedDivisionsList.length !== 0
                        "
                        :disabled="!usersDivisions.length"
                        type="button"
                        class="add_more"
                        :class="{ add_more_disabled: !usersDivisions.length }"
                        @click="addingNewDivision = true"
                    >
                        <span>Add another</span>
                        <FontAwesomeIcon class="add_icon" :icon="['far', 'plus']" />
                    </button>
                </SettingsFieldSet>
            </SettingsBlock>
        </SettingsForm>
        <portal to="editUserFooter">
            <div class="footer">
                <Button :loading="isSaving" :disabled="!isDirty" @click.native="prepareForSave">
                    Save
                </Button>
                <Button
                    class="cancel-button"
                    buttonStyle="secondary"
                    @click.native="$router.push(previousPage)"
                >
                    Cancel
                </Button>
            </div>
        </portal>
        <ConfirmPopup
            v-if="!!popup"
            :title="popupTitle"
            :message="popupMessage"
            :confirmBtn="btnPopupMessage"
            :cancel-btn="'Cancel'"
            @confirm="popupConfirmMethod"
            @cancel="popup = null"
            :closeAllowed="true"
        />
    </div>
</template>

<script setup>
import cloneDeep from "lodash.clonedeep";
import deepEqual from "deep-equal";
import * as Sentry from "@sentry/vue";
import {
    InlineButton,
    TextInput,
    Dropdown,
    Button,
    ConfirmPopup,
    Checkbox,
} from "@feedbackcompany/feedback-company-vue-components";
import SettingsForm from "@/components/layout/SettingsForm.vue";
import SettingsBlock from "@/components/layout/SettingsBlock.vue";
import SettingsFieldSet from "@/components/layout/SettingsFieldSet.vue";
import { resolveErrorMessage } from "@/validation/resolveErrorMessageComposition";
import { scrollToFirstError } from "@/validation";
import { getEditUserSettingsSchema } from "@/validation/schemas/editUser";
import { updateUser } from "@/graphql/updateUser.gql";
import { getUserConnections } from "@/graphql/getUserConnections.gql";
import { ref, watch, onMounted, computed, nextTick } from "vue";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { useVuelidate } from "@vuelidate/core";
import { useRoute, useStore } from "@/helpers/composition-helper";
import { getUser } from "@/graphql/getUser.gql";
import { getCompany } from "@/graphql/overview/getCompany.gql";
import { faTimes, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faTimes, faPlus);

// props
const props = defineProps({
    previousPage: {
        type: String,
        default: "/users",
    },
    data: {
        type: Object,
        required: true,
    },
});

const route = useRoute();
const store = useStore();

const formEl = ref(null);

// data
const loading = ref(true);
const isSaving = ref(false);
const user = ref(cloneDeep(props.data));
const initialUserData = ref(cloneDeep(props.data));
const connectionUsers = ref([]);
const enableSelectDivision = ref(false);
const languages = ref([
    {
        value: "nl",
        displayValue: "Dutch",
    },
    {
        value: "en",
        displayValue: "English",
    },
]);
const divisionsList = ref([]);
const deleteIndex = ref(null);
const usersDivisions = ref([]);
const popup = ref(null);
const popupOptions = ref({
    DELETE_DIVISION: "DELETE_DIVISION",
    UNSELECT_CHECKBOX: "UNSELECT_CHECKBOX",
    CHANGE_USER_EMAIL: "CHANGE_USER_EMAIL",
});
const addingNewDivision = ref(false);
const newDivisionId = ref(null);

// computed
const hasUserPlatforms = computed(() => {
    return user.value.connectionPlatforms?.length > 0;
});
const isDirty = computed(() => {
    if (user.value.companyLevelAccess) {
        return (
            !deepEqual(user.value, initialUserData.value) ||
            user.value.companyLevelAccess != !enableSelectDivision.value
        );
    }
    return (
        !deepEqual(user.value, initialUserData.value) ||
        !deepEqual(user.value.divisions, usersDivisions.value)
    );
});

const popupTitle = computed(() => {
    if (popup.value === popupOptions.value.UNSELECT_CHECKBOX) return "Warning";
    return "Attention";
});

const popupMessage = computed(() => {
    if (popup.value === popupOptions.value.UNSELECT_CHECKBOX)
        return "It’s not possible to uncheck this option because this user has access to specific divisions. You CAN unselect this option if you delete the divisions it has access to first. After that you can unselect the select division part and then the user will have access to ALL divisions.";

    if (popup.value === popupOptions.value.CHANGE_USER_EMAIL)
        return "When the email is changed, the user needs to verify the new email when logging in for the first time.";

    return "Deleting this division from the list would mean that this user can’t access this division anymore.<br><br> Are you sure you want to continue?";
});

const btnPopupMessage = computed(() => {
    if (popup.value === popupOptions.value.UNSELECT_CHECKBOX) return "Understood";

    if (popup.value === popupOptions.value.CHANGE_USER_EMAIL) return "Send email";

    return "Confirm";
});

const prunedDivisionsArray = computed(() => {
    return usersDivisions.value.filter((div) => {
        return !!div.id;
    });
});

const unselectedDivisionsList = computed(() => {
    const newUserDivs = usersDivisions.value.map((div) => div.id);
    return divisionsList.value.filter((div) => {
        return !newUserDivs.includes(div.value);
    });
});

function deleteDivision() {
    usersDivisions.value.splice(deleteIndex.value, 1);
    popup.value = null;
}

function userUnderstood() {
    enableSelectDivision.value = true;
    popup.value = null;
}

// validations
const v$ = useVuelidate(getEditUserSettingsSchema(), {
    user,
    newDivisionId,
    enableSelectDivision,
    usersDivisions,
    addingNewDivision,
});

// workaround for composition api, find a way to use forEach in vulidate3
const areDivisionsValid = computed(() => {
    return v$.value.$dirty && !usersDivisions.value.find((el) => el.id === "");
});

// mutation
const { mutate, onDone, onError } = useMutation(updateUser);
onDone((result) => {
    store.dispatch("pushNotification", {
        type: "success",
        title: "User updated",
        message: `You've successfully updated user "${result.data.updateUser.username}"`,
    });

    isSaving.value = false;
    popup.value = null;
});

onError((error) => {
    Sentry.captureException(error);
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "The user could not be updated.",
    });
    isSaving.value = false;
    popup.value = null;
});

// functions
async function save() {
    isSaving.value = true;
    const { firstName, lastName, email, keycloakLanguage, connection } = user.value;

    const variables = {
        input: {
            firstName,
            lastName,
            email,
            keycloakLanguage,
            connection: connection !== "Choose a connection" ? connection : null,
        },
        userId: route.params.userId,
    };
    if (usersDivisions.value.length > 0) {
        variables.divisions = usersDivisions.value.map((division) => division.id);
    } else {
        variables.companyId = user.value.company.id;
    }
    mutate(variables, {
        refetchQueries: [{ query: getUser, variables: { id: user.value.id } }],
    });
}

function prepareForSave() {
    v$.value.$touch();
    if (v$.value.$error || !!formEl.value.$el.querySelector("[class*=--invalid]")) {
        nextTick(() => {
            scrollToFirstError(formEl.value.$el);
        });
        return;
    }
    if (v$.value.$error || !areDivisionsValid.value) {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Error",
            message: "The user could not be updated. Check the fields for errors.",
        });
        return;
    }
    if (user.value.email !== initialUserData.value.email) {
        popup.value = popupOptions.value.CHANGE_USER_EMAIL;
    } else {
        save();
    }
}

function handleResult(result) {
    if (hasUserPlatforms.value) {
        const connections = cloneDeep(result.connections);
        connectionUsers.value = connections.map((item) => ({
            value: item,
            displayValue: item,
        }));
    }
    loading.value = false;
}

function handleCompanyResult(result) {
    result.company.divisions.forEach((element) => {
        divisionsList.value.push({ value: element.id, displayValue: element.name });
    });
}

function toggleCheckbox() {
    usersDivisions.value = prunedDivisionsArray.value;
    if (enableSelectDivision.value && usersDivisions.value.length > 0) {
        popup.value = popupOptions.value.UNSELECT_CHECKBOX;
    }
}

function prepareDelete(index) {
    if (!usersDivisions.value[index].id) {
        usersDivisions.value.splice(index, 1);
        return;
    }
    popup.value = popupOptions.value.DELETE_DIVISION;
}

// this computed method is under all the functions because of async
const popupConfirmMethod = computed(() => {
    if (popup.value === popupOptions.value.UNSELECT_CHECKBOX) return userUnderstood;
    if (popup.value === popupOptions.value.CHANGE_USER_EMAIL) return save;
    return deleteDivision;
});

onMounted(async () => {
    const { result, onResult } = useQuery(getUserConnections, {
        platforms: user.value.connectionPlatforms,
    });
    if (result.value) handleResult(result.value);
    onResult((response) => {
        handleResult(response.data);
    });

    if (user.value.company) {
        const { result: companyResult, onResult: onCompanyResult } = useQuery(getCompany, {
            id: user.value.company.id,
        });
        if (companyResult.value) handleCompanyResult(companyResult.value);
        onCompanyResult((response) => {
            handleCompanyResult(response.data);
        });
    }

    if (!user.value.companyLevelAccess) {
        usersDivisions.value = cloneDeep(user.value.divisions);
        enableSelectDivision.value = true;
    }
});

watch(
    () => props.data,
    () => {
        user.value = cloneDeep(props.data);
        initialUserData.value = cloneDeep(props.data);

        if (!user.value.companyLevelAccess) {
            usersDivisions.value = cloneDeep(user.value.divisions);
            enableSelectDivision.value = true;
        }
    }
);

defineExpose({
    isDirty,
    save,
});

watch(newDivisionId, () => {
    if (newDivisionId.value) usersDivisions.value.push({ id: newDivisionId.value });
    addingNewDivision.value = false;
    newDivisionId.value = null;
});
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables.scss";

.settings_block {
    width: 100%;
    margin-top: 40px;

    &:first-of-type {
        margin-top: 0px;
    }

    &:last-of-type {
        margin-bottom: 40px;
    }
}
.settings_fieldset * {
    display: inline-block;
    margin-bottom: 12px;
}
.settings_fieldset .subtitle3 {
    margin-top: 18px;
}
.settings_fieldset::v-deep .formgroup__header {
    padding: 0 0 0 0;
}
.text_input_holder {
    width: 100%;
}
.language_body {
    margin-bottom: 12px;
}
.settings_form::v-deep .formgroup__header {
    margin-bottom: 0;
}
.unknown_platform {
    @extend %body2_style;
    color: $red;
    padding-left: 14px;
    letter-spacing: 0px;
}
.buttons {
    width: 100%;
}
.footer {
    width: 100%;
    display: flex;
    .cancel-button {
        margin-left: 12px;
    }
}
.division_picker {
    display: flex;
}
.text_input_holder {
    width: 100%;
}
.add_more {
    display: flex;
    align-items: center;
    @extend %button_typography_style;
    border: none;
    background-color: $white;
    cursor: pointer;
    padding: 0;
    color: $blue;

    &:hover {
        color: $blue_sapphire;
    }
    &_disabled {
        color: $grey_french;
        &:hover {
            color: $grey_french;
        }
    }

    span {
        padding-right: 8px;
    }
}
.checkbox {
    margin-top: 20px;
}
.delete_icon {
    margin-top: 12px;
}
</style>
