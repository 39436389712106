/* eslint-disable no-console */
import { cloneDeep } from "lodash";
import getTracebuzzIntegration from "../graphql/integrations/getTracebuzzIntegration.gql";
import kidsKonnectIntegration from "../graphql/integrations/getKidsKonnectIntegration.gql";

class ProviderService {
    constructor($apollo) {
        this.$apollo = $apollo;
    }

    // eslint-disable-next-line class-methods-use-this
    getProviderData(provider) {
        let name;
        let query;
        // @todo provider names should be placed on a config file?
        switch (provider) {
            case "tracebuzz":
                name = "Tracebuzz";
                query = getTracebuzzIntegration;
                break;
            case "kids-konnect":
                name = "Kids Konnect";
                query = kidsKonnectIntegration;
                break;
            default:
                name = null;
                query = null;
        }
        return { name, query };
    }

    async get(provider, connectionId = null) {
        const providerData = this.getProviderData(provider);

        if (connectionId) {
            try {
                const res = await this.$apollo.query({
                    query: providerData.query,
                    variables: {
                        id: connectionId,
                    },
                });

                let connection = null;

                if (providerData.name === "Tracebuzz") {
                    const resData = cloneDeep(res.data.tracebuzzIntegration);

                    connection = {
                        provider,
                        name: providerData.name,
                        id: parseInt(resData.id, 10),
                        invitationLink: resData.message,
                        enabled: resData.enabled,
                        clientSecret: resData.credentials.clientSecret,
                        username: resData.credentials.username,
                        password: resData.credentials.password,
                        currentPlatform: resData.platform,
                        apiUser: resData.username,
                        questionnaire: {
                            id: resData.questionnaireId,
                            name: resData.questionnaireName,
                        },
                    };
                }
                if (providerData.name === "Kids Konnect") {
                    const resData = cloneDeep(res.data.kidsKonnectIntegration);

                    connection = {
                        provider,
                        name: providerData.name,
                        id: parseInt(resData.id, 10),
                        enabled: resData.enabled,
                        username: resData.username,
                        password: resData.password,
                        currentPlatform: resData.platform,
                        apiToken: resData.apiToken,
                        evApiKey: resData.evApiKey,
                        evUsername: resData.evUsername,
                        cron: resData.cron,
                        createdAt: resData.createdAt,
                        companyId: resData.companyId,
                        initialDelay: resData.initialDelay,
                        initialDelayUnit: resData.initialDelayUnit,
                        reminderDelay: resData.reminderDelay,
                        reminderDelayUnit: resData.reminderDelayUnit,
                        questionnaire: {
                            id: resData.questionnaireId,
                            name: resData.questionnaireName,
                        },
                        fromDate: resData.fromDate,
                        fromDateType: resData.fromDateType,
                    };
                }

                return Promise.resolve(connection);
            } catch (e) {
                console.log(e);
            }
        } else {
            let connection = null;

            if (providerData.name === "Tracebuzz") {
                connection = {
                    provider,
                    name: providerData.name,
                    id: null,
                    invitationLink: "",
                    enabled: true,
                    clientSecret: "",
                    username: "",
                    password: "",
                    currentPlatform: "",
                    apiUser: "",
                    questionnaire: { name: "", id: null },
                };
            }
            if (providerData.name === "Kids Konnect") {
                connection = {
                    provider,
                    name: providerData.name,
                    id: null,
                    enabled: true,
                    username: "",
                    password: "",
                    currentPlatform: "",
                    apiToken: "",
                    evApiKey: "",
                    evUsername: "",
                    cron: null,
                    createdAt: null,
                    companyId: "",
                    initialDelay: 1,
                    initialDelayUnit: "DAYS",
                    reminderDelay: 5,
                    reminderDelayUnit: "DAYS",
                    questionnaire: { name: "", id: null },
                };
            }

            return Promise.resolve(connection);
        }
    }
}

export default ProviderService;
