<template>
    <SettingsForm class="settings_form" ref="formEl">
        <SettingsBlock class="settings_block settings_block__credentials" title="Settings">
            <SettingsFieldSet class="settings_fieldset" label="">
                <label class="subtitle3">Kids Konnect settings</label>
                <TextInput
                    label="API token"
                    v-model="innerConnectionData.apiToken"
                    enableExternalValidation
                    :externalValidationError="
                        v$.innerConnectionData.apiToken.$error || isConnectionInvalid
                    "
                    :externalValidationErrorMessage="
                        isConnectionInvalid
                            ? 'Credentials are not valid. Please check them.'
                            : resolveErrorMessage(v$.innerConnectionData.apiToken)
                    "
                />

                <TextInput
                    label="Username"
                    v-model="innerConnectionData.username"
                    enableExternalValidation
                    :externalValidationError="
                        v$.innerConnectionData.username.$error || isConnectionInvalid
                    "
                    :externalValidationErrorMessage="
                        isConnectionInvalid
                            ? 'Credentials are not valid. Please check them.'
                            : resolveErrorMessage(v$.innerConnectionData.username)
                    "
                />

                <TextInput
                    label="Password"
                    is-password
                    v-model="innerConnectionData.password"
                    enableExternalValidation
                    :externalValidationError="
                        v$.innerConnectionData.password.$error || isConnectionInvalid
                    "
                    :externalValidationErrorMessage="
                        isConnectionInvalid
                            ? 'Credentials are not valid. Please check them.'
                            : resolveErrorMessage(v$.innerConnectionData.password)
                    "
                />
            </SettingsFieldSet>
        </SettingsBlock>

        <SettingsBlock class="settings_block" title="API user">
            <SettingsFieldSet class="settings_fieldset" label="Select a platform">
                <label class="body2 language_body">
                    Before selecting a user, please select a platform.
                </label>
                <Dropdown
                    v-model="innerConnectionData.currentPlatform"
                    placeholder="Choose a platform"
                    :searchAble="platforms.length > 3"
                    :options="platforms"
                    enableExternalValidation
                    :externalValidationError="v$.innerConnectionData.currentPlatform.$error"
                    :externalValidationErrorMessage="
                        resolveErrorMessage(v$.innerConnectionData.currentPlatform)
                    "
                />
            </SettingsFieldSet>

            <SettingsFieldSet class="settings_fieldset" label="Select a user">
                <label class="body2 language_body">
                    Select a user that you want to connect to this connection.
                </label>
                <Dropdown
                    :disabled="!innerConnectionData.currentPlatform || !users.length"
                    v-model="innerConnectionData.evUsername"
                    placeholder="Choose a user"
                    :searchAble="users.length > 3"
                    :options="users"
                    enableExternalValidation
                    :externalValidationError="v$.innerConnectionData.evUsername.$error"
                    :externalValidationErrorMessage="
                        resolveErrorMessage(v$.innerConnectionData.evUsername)
                    "
                />
            </SettingsFieldSet>

            <SettingsFieldSet class="settings_fieldset" label="EV API key">
                <TextInput
                    v-model="innerConnectionData.evApiKey"
                    label="Your API key"
                    enableExternalValidation
                    :externalValidationError="v$.innerConnectionData.evApiKey.$error"
                    :externalValidationErrorMessage="
                        resolveErrorMessage(v$.innerConnectionData.evApiKey)
                    "
                />
            </SettingsFieldSet>
        </SettingsBlock>

        <SettingsBlock class="settings_block" title="Select a questionnaire">
            <SettingsFieldSet class="settings_fieldset" label="Questionnaire">
                <label class="body2 language_body">
                    Select a platform and API user before selecting a questionnaire. The
                    questionnaire is based on this data.
                </label>
            </SettingsFieldSet>

            <div
                class="questionnaire_block selection_confirmation"
                :class="questBlockDisabledClass"
            >
                <div>
                    <div class="subtitle3">Selected questionnaire</div>
                    <div class="body1" :class="selectedQuestClass">
                        {{ selectedQuestName }}

                        <TextInput
                            class="selected-quest"
                            v-model="innerConnectionData.questionnaire.name"
                            enableExternalValidation
                            :externalValidationError="
                                v$.innerConnectionData.questionnaire.name.$error
                            "
                            externalValidationErrorMessage="Please select a questionnaire"
                        />
                    </div>
                </div>
            </div>

            <TreeView
                v-if="treeItems.length"
                class="questionnaire_block"
                :items="treeItems"
                :onClickHandler="setActive"
            />
        </SettingsBlock>
        <SettingsBlock class="settings_block" title="Date selector">
            <SettingsFieldSet label="">
                <label class="body2 language_body">
                    Choose the from date that the system should look at to send an invitation to the
                    people in the Kids Konnect API. People with the matching date or dates after
                    that will be scheduled for an invitation
                </label>

                <RadioList
                    class="dates"
                    v-model="innerConnectionData.fromDateType"
                    required
                    :options="[
                        {
                            value: 'START_DATE',
                            displayValue: 'Starts_at date',
                        },
                        {
                            value: 'END_DATE',
                            displayValue: 'Ends_at date',
                        },
                    ]"
                    enableExternalValidation
                    :externalValidationError="v$.innerConnectionData.fromDateType.$error"
                    externalValidationErrorMessage="Please select an option"
                />
                <DateInput
                    v-model="innerConnectionData.fromDate"
                    isRequired
                    enableExternalValidation
                    :externalValidationError="v$.innerConnectionData.fromDate.$error"
                    externalValidationErrorMessage="Please select a date"
                />
            </SettingsFieldSet>
        </SettingsBlock>

        <SettingsBlock class="settings_block" title="Scheduling invitations">
            <SettingsFieldSet class="settings_fieldset" label="First invitation delay">
                <TextInput
                    v-model="innerConnectionData.initialDelay"
                    numeric
                    :min="0"
                    class="numeric_input"
                    required
                />
            </SettingsFieldSet>
            <SettingsFieldSet class="settings_fieldset" label="Reminder delay">
                <Checkbox
                    v-model="hasReminderDelay"
                    label="Add a reminder delay"
                    class="delay_checkbox"
                />
                <label class="body2 language_body">The minimum delay is 5 days.</label>
                <TextInput
                    v-model="innerConnectionData.reminderDelay"
                    numeric
                    :min="5"
                    class="numeric_input"
                    :required="hasReminderDelay"
                    :disabled="!hasReminderDelay"
                />
            </SettingsFieldSet>
        </SettingsBlock>
    </SettingsForm>
</template>

<script setup>
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { ref, watch, computed, onMounted, nextTick } from "vue";
import { useStore, useRoute } from "@/helpers/composition-helper";
import { useQuery, useLazyQuery } from "@vue/apollo-composable";
import SettingsForm from "@/components/layout/SettingsForm.vue";
import SettingsBlock from "@/components/layout/SettingsBlock.vue";
import SettingsFieldSet from "@/components/layout/SettingsFieldSet.vue";
import {
    TextInput,
    Dropdown,
    FieldToClipboard,
    TreeView,
    Checkbox,
    RadioList,
    DateInput,
} from "@feedbackcompany/feedback-company-vue-components";
import { resolveErrorMessage } from "@/validation/resolveErrorMessageComposition";
import { scrollToFirstError } from "@/validation/";
import { useVuelidate } from "@vuelidate/core";
import { createKidsKonnectConnection } from "@/validation/schemas/connections";
import getIntegrationQuestionnaireCategoryNodes from "@/graphql/integrations/getIntegrationQuestionnaireCategoryNodes.gql";
import getEntityUsers from "@/graphql/dashboards/getEntityUsers.gql";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimesCircle, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import testTracebuzzConnection from "@/graphql/connections/testTracebuzzConnection.gql";
import cloneDeep from "lodash.clonedeep";
import getCompany from "../../graphql/overview/getCompany.gql";

library.add(faTimesCircle, faCheckCircle);

const props = defineProps({
    connection: {
        type: Object,
        required: true,
    },
});
const store = useStore();
const route = useRoute();

const formEl = ref(null);

const treeItems = ref([]);
const users = ref([]);
const emptyData = ref({
    provider: "",
    name: "",
    id: null,
    apiToken: "",
    username: "",
    password: "",
    currentPlatform: "",
    evUsername: "",
    evApiKey: "",
    questionnaire: { name: "", isActive: false },
    initialDelay: 1,
    reminderDelay: 5,
    fromDateType: null,
    fromDate: null,
});
const innerConnectionData = ref(cloneDeep(emptyData.value));
const company = ref(null);
const isConnectionChecked = ref(false);
const isConnectionValid = ref(false);
const hasReminderDelay = ref(false);
const showToaster = ref(false);
const selectedTreeItem = ref(null);

const emit = defineEmits(["save"]);

// validations
const v$ = useVuelidate(createKidsKonnectConnection(), { innerConnectionData });

// computed
const selectedQuestClass = computed(() => {
    const { name } = innerConnectionData.value.questionnaire;
    return {
        no_questionnaire_selected: name === "" || name === null,
    };
});
const questBlockDisabledClass = computed(() => {
    const { currentPlatform, evUsername } = innerConnectionData.value;
    const currentPlatformIsEmpty = currentPlatform === "";
    const evUsernameIsEmpty = evUsername === "";

    return {
        questionnaire_block_disabled: currentPlatformIsEmpty || evUsernameIsEmpty,
    };
});
const selectedQuestName = computed(() => {
    const { name } = innerConnectionData.value.questionnaire;
    return name !== "" && name !== null ? name : "No questionnaire selected";
});
const entities = computed(() => {
    if (company.value.entityId) {
        return [{ entityId: company.value.entityId, entityType: company.value.entityType }];
    }
    return company.value.divisions.map((element) => ({
        entityId: element.entityId,
        entityType: element.entityType,
    }));
});
const platforms = computed(() => {
    if (!company.value) return [];
    const list = company.value.platform
        ? [company.value.platform]
        : company.value.divisionPlatforms;
    return list.map((platform) => ({
        value: platform,
        displayValue: platform,
    }));
});
const isConnectionInvalid = computed(() => {
    return isConnectionChecked.value && !isConnectionValid.value;
});

// methods
function setActive(item) {
    selectedTreeItem.value = item;
    if (item.leaf) {
        if (item.isActive || item.children) return;
        if (innerConnectionData.value.questionnaire.name !== "")
            innerConnectionData.value.questionnaire.isActive = false;
        item.isActive = true;
        innerConnectionData.value.questionnaire = item;
    } else {
        loadQuestData(getIntegrationQuestionnaireCategoryNodes, {
            categoryNodeId: item.id,
            platform: innerConnectionData.value.currentPlatform,
            username: innerConnectionData.value.evUsername,
        });
    }
}

/**
 * Recursive function to find the active leaf independently how deep is it.
 */
function activateTreeLeaf(tree) {
    const rawId = innerConnectionData.value.questionnaire.id;
    const storedId = `ric_${rawId}`;

    tree.forEach((item) => {
        if (item.id === storedId || item.id === rawId) {
            setActive(item);
        } else {
            item.isActive = false;
        }

        if ("children" in item) {
            activateTreeLeaf(item.children);
        }
    });
}

const { load: loadQuestData, onResult: onQuestDataResult } = useLazyQuery(
    getIntegrationQuestionnaireCategoryNodes
);
onQuestDataResult((res) => {
    if (res.loading) return;
    const resData = cloneDeep(res.data.integrationQuestionnaireCategoryNodes);

    selectedTreeItem.value.children = prepareDisplayData(resData);
    selectedTreeItem.value = null;
});

const { load: loadRootQuestData, onResult: onRootQuestDataResult } = useLazyQuery(
    getIntegrationQuestionnaireCategoryNodes
);

onRootQuestDataResult((res) => {
    if (res.loading) return;
    const resData = cloneDeep(res.data.integrationQuestionnaireCategoryNodes);

    if (innerConnectionData.value.questionnaire.id !== null) {
        activateTreeLeaf(resData);
    }
    treeItems.value = prepareDisplayData(resData);
});

function setQuestionnaireData() {
    loadRootQuestData(getIntegrationQuestionnaireCategoryNodes, {
        categoryNodeId: "rootnode",
        platform: innerConnectionData.value.currentPlatform,
        username: innerConnectionData.value.evUsername,
    });
}

function prepareDisplayData(treeData) {
    return treeData.map((node) => {
        if (!node.leaf) {
            node.children = [];
            node.isOpen = false;
        }
        node.isActive = false;
        return node;
    });
}

function showError() {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Tested",
        message: "Connection is unsuccessful. Please check your credentials",
    });
}

function showTestConnectionToaster() {
    if (showToaster.value) {
        if (isConnectionValid.value) {
            store.dispatch("pushNotification", {
                type: "success",
                title: "Tested",
                message: "Connection is successful.",
            });
        } else {
            showError();
        }
    }
}

/**
 * Returns the user input.
 *
 * This method is really only called by the parent since this
 * component itself is a piece of a bigger form.
 */
function submitDataToParent() {
    v$.value.$touch();
    if (v$.value.$error || !!formEl.value.$el.querySelector("[class*=--invalid]")) {
        nextTick(() => {
            scrollToFirstError(formEl.value.$el);
        });
        return;
    }
    emit("save", {
        ...innerConnectionData.value,
        reminderDelay: hasReminderDelay.value ? innerConnectionData.value.reminderDelay : 0,
    });
}

function setPlatformUsersData(entityUsers) {
    const entUsersList = cloneDeep(entityUsers);
    users.value = entUsersList.map((item) => ({
        value: item.username,
        displayValue: item.username,
    }));
}

const { load: loadEntityUsers, onResult: onEntityUsersResult } = useLazyQuery(getEntityUsers);
onEntityUsersResult((res) => {
    if (res.data) {
        setPlatformUsersData(res.data.entityUsers);
    }
});

function setCompanyAndGetUsers(companyName) {
    company.value = companyName;

    // load entity users
    loadEntityUsers(getEntityUsers, {
        entities: entities.value,
        platform: company.value.platform,
    });
}

function reset() {
    innerConnectionData.value = cloneDeep(emptyData.value);
}

// watchers
watch(
    () => innerConnectionData.value.currentPlatform,
    (newPlatform, oldPlatform) => {
        if (newPlatform != oldPlatform && !newPlatform) {
            innerConnectionData.value.evUsername = "";
        }
    }
);

watch(
    () => innerConnectionData.value.evUsername,
    (newEvUsername, oldEvUsername) => {
        if (oldEvUsername !== "" && props.connection.evUsername !== newEvUsername) {
            innerConnectionData.value.questionnaire = { name: "", isActive: false };
        }
        treeItems.value = [];
        setQuestionnaireData();
    }
);

onMounted(async () => {
    const { result, onResult } = useQuery(getCompany, {
        id: route.params.id,
    });
    if (result.value) setCompanyAndGetUsers(result.value.company);
    onResult((res) => {
        setCompanyAndGetUsers(res.data.company);
    });
    // {...props.connection} for detaching reference (cloning).
    innerConnectionData.value = { ...props.connection };
    hasReminderDelay.value = props.connection.reminderDelay > 0;
    delete innerConnectionData.value.enabled;
    innerConnectionData.value.reminderDelay =
        props.connection.reminderDelay > 0 ? props.connection.reminderDelay : 5;
});

defineExpose({
    submitDataToParent,
    reset,
});
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables";
@import "~include-media";

.settings_block {
    margin-bottom: 40px;

    &.settings_block__credentials {
        .subtitle3 {
            margin-bottom: 10px;
            display: block;
        }

        .text_input_holder {
            margin-bottom: 10px;
        }
    }

    .test_connection {
        @extend %button_typography_style;
        color: $blue;
        cursor: pointer;

        .status-icon--invalid {
            color: $red;
        }

        .test_connection_button {
            &:hover {
                color: $blue_sapphire;
            }
        }
    }
}

.language_body {
    margin-bottom: 12px;
    display: block;
    max-width: 400px;
}

.settings_form {
    padding-bottom: 100px;

    ::v-deep .formgroup__header {
        margin-bottom: 0;
    }
}

.unknown_platform {
    @extend %body2_style;
    color: $red;
    padding-left: 14px;
    letter-spacing: 0px;
}

.questionnaire_block {
    margin-top: 12px;
    padding: 24px;
    border: 1px solid $grey_alabaster;
    border-radius: 4px;

    &.selection_confirmation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    &.tree {
        margin-bottom: 0;
        padding: 8px 24px 8px 24px;
    }

    &_disabled {
        background-color: $grey-bg;
        border-color: $grey_mischka;
        & * {
            color: $grey_mischka !important;
        }
    }
}

.no_questionnaire_selected {
    color: $grey_bombay;
}

.selected-quest {
    height: 1px;

    ::v-deep {
        .text_input {
            visibility: hidden;
            height: 1px;
        }

        .validation-message {
            margin: 0;
        }
    }
}
.numeric_input {
    max-width: 90px;
    margin-top: 10px;
}
.delay_checkbox {
    display: inline-block;
    margin: 16px 0 24px;
}
.dates {
    margin-bottom: 10px;
    ::v-deep {
        span {
            font-weight: bold;
        }
    }
}
// this v-deep is used for year picker bug SP-763
::v-deep {
    .vc-popover-content-wrapper .is-interactive {
        transform: translate(43px, 38px) !important;
    }
}
</style>
