<template>
    <GenericPage
        fullWidth
        :headerProps="{
            title: 'Divisions',
            subtitle: 'An overview to divide and honor.',
            disableBackButton: true,
        }"
    >
        <Table
            class="divisions_table"
            title="Divisions overview"
            :columns="[
                { label: 'Language', property: 'language' },
                { label: 'Name', property: 'name' },
                { label: 'URL', property: 'link' },
                { label: 'Registration date', property: 'creationDate' },
                { label: 'Teamleader UUID', property: 'teamleaderUuid' },
                { label: 'Service', property: 'displayedServices' },
                { label: '', property: '' },
            ]"
            :disableHeaderTextWrap="true"
            sortable
            :empty="tableState.data.length === 0"
            :sortableColumns="[
                'language',
                'name',
                'link',
                'creationDate',
                'teamleaderUuid',
                'displayedServices',
            ]"
            :currentPage="tableState.currentPage"
            :currentSorted="tableState.currentSorted"
            :currentSortDirection="tableState.currentSortDirection"
            :searchable="tableState.searchEnabled"
            :searchableColumns="[
                'language',
                'name',
                'link',
                'formattedCreationDate',
                'teamleaderUuid',
                'displayedServices',
            ]"
            :searching="searchTerm !== null"
            :searchTerm="searchTermInit"
            :paginate="
                tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
            "
            :defaultPaginationSize="tableState.paginationSize"
            searchbarPlaceholder="Search"
            :pages="tableState.pages"
            @sort="sort($event)"
            @search="search($event)"
            @pageChange="onPageChange"
            @onPaginationSizeChange="setPaginationSize($event)"
        >
            <TableRow
                v-for="(row, index) in tableState.data"
                v-bind:key="index"
                v-bind:isLast="tableState.data.length - 1 === index"
                bordered
            >
                <TableCell>{{ row.language }}</TableCell>
                <TableCell>
                    <router-link :to="getDivisionUrl(row)" class="url">
                        {{ row.name }}
                    </router-link>
                </TableCell>
                <TableCell>
                    <a class="url" :href="row.link" target="_blank">
                        {{ row.link }}
                    </a>
                </TableCell>
                <TableCell>{{ row.formattedCreationDate }}</TableCell>
                <TableCell>{{ row.teamleaderUuid }}</TableCell>
                <TableCell>{{ row.displayedServices }}</TableCell>
                <TableCell class="controls">
                    <IconButton label="">
                        <router-link :to="getDivisionUrl(row)" v-tippy="'Edit division'">
                            <font-awesome-icon :icon="['far', 'edit']" />
                        </router-link>
                    </IconButton>
                </TableCell>
            </TableRow>
            <template v-slot:empty>
                <div v-if="isLoading" class="loading-and-messages">
                    <LoaderCircular />
                </div>
                <span v-else-if="true" class="loading-and-messages"> No results </span>
                <span v-else class="loading-and-messages"> No divisions </span>
            </template>
            <template v-slot:pagination-indicator>
                <PaginationIndicator
                    :pages="tableState.pages"
                    :currentPage="tableState.currentPage"
                    :currentPageItemCount="tableState.data.length"
                    :current-page-range-start="tableState.currentPageRangeStart"
                    :current-page-range-end="tableState.currentPageRangeEnd"
                    :itemCount="tableState.itemCount"
                    itemDescription="divisions"
                    ofString="of"
                />
            </template>
        </Table>
    </GenericPage>
</template>

<script setup>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { format, parseISO } from "date-fns";
import {
    Table,
    TableRow,
    TableCell,
    PaginationIndicator,
    LoaderCircular,
    IconButton,
    useTableState,
} from "@feedbackcompany/feedback-company-vue-components";
import GenericPage from "@/components/layout/GenericPage.vue";
import cloneDeep from "lodash.clonedeep";
import { useQuery } from "@vue/apollo-composable";
import { ref, onMounted, watch } from "vue";
import { useStore } from "@/helpers/composition-helper";
import getDivisions from "../graphql/overview/getDivisions.gql";

const store = useStore();

library.add(faEdit);

const isLoading = ref(true);
const { data, setData, sort, search, searchTerm, setPage, setPaginationSize } = useTableState();
const tableState = data;
const searchTermInit = ref(null);

function onPageChange(page) {
    setPage(page);
}
function formatDate(date) {
    return format(parseISO(date), "dd-MM-yyyy", "en");
}
function getDivisionUrl(row) {
    return `/division/${row.id}`;
}
function mapServices(services) {
    return services
        .map((element) => {
            if (element === "APPRECIATION") {
                return "AP";
            }
            return "EV";
        })
        .join("/");
}

function handleResult(result) {
    const divisions = cloneDeep(result.divisions);
    divisions.forEach((element) => {
        element.formattedCreationDate = formatDate(element.creationDate);
        element.displayedServices = mapServices(element.services);
    });
    divisions.sort((item1, item2) => (item1.name > item2.name ? 1 : -1));

    const oldTableState = store.getters["OverviewStore/getState"]("divisions");
    if (oldTableState.searchTerm) {
        searchTermInit.value = oldTableState.searchTerm;
    }
    if (oldTableState.currentSorted) {
        sort({
            column: oldTableState.currentSorted,
            direction: oldTableState.currentSortDirection,
        });
    }
    setData(divisions, {
        searchTermThreshold: 0.1,
        paginationSize: 10,
        paginationThreshold: 11,
        paginationEnabled: true,
    });
}

onMounted(async () => {
    const { result, onResult, loading } = useQuery(getDivisions);
    if (result.value) handleResult(result.value);
    onResult((response) => {
        handleResult(response.data);
    });
    isLoading.value = loading;
});

watch(tableState, () => {
    store.commit("OverviewStore/setState", { page: "divisions", newState: tableState.value });
});
</script>
<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";

.divisions_table {
    width: 100%;
}
.controls {
    display: flex;
    justify-content: flex-end;
}
.fa-edit {
    color: #4389f8;
    &:hover {
        color: $blue_sapphire;
    }
}
.url {
    color: #4389f8;
    text-decoration: none;
}
.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.divisions_table::v-deep .table_header__cell {
    padding: 36px 0px 32px 24px;
}
</style>
